import React, { Component, Fragment } from 'react';
import { Modal, Button, Form, Segment, Message, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
    checkGiftDetails,
    sendClaimSecurityCode,
    confirmClaim,
    clearGiftCheckDetails
} from '../../../store/actions/newClaimActions'

const initState = {
    modalOpen: false,
    giftID: '',
    securityCode: ''
}

class NewClaim extends Component {
    state = initState;

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => {
        this.props.clearGiftCheckDetails();
        this.setState(initState);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleNext = () => {
        const { giftID, securityCode } = this.state;
        const { valid, codeSent } = this.props.newClaim;



        //Checking if already gift is searched and ID is valid
        if (valid) {
            //Record is valid. 
            //Check if the security code is already sent
            if (codeSent) {
                //Code is sent. 
                //Check if the security code is entered
                if (securityCode) {
                    //SecurityCode is entered
                    //Confirming the claim
                    const { giftDocID } = this.props.newClaim.giftData
                    this.props.confirmClaim(giftDocID, securityCode)
                }
            } else {
                //Code is not sent.
                //Sending the security code
                const { giftDocID } = this.props.newClaim.giftData
                this.props.sendClaimSecurityCode(giftDocID);
            }

        } else {
            //Gift ID is not validated. Checking if the Gift ID is entered
            if (giftID) {
                //Gift ID is entered. Checking gift details
                this.props.checkGiftDetails(giftID);
            }
        }

    }

    render() {
        const { modalOpen, giftID, securityCode } = this.state;
        const { newClaim, dppRebate } = this.props;

        const { processing, valid, codeSent, claimed, processError, errorMsg, errorData, giftData } = newClaim;


        return (
            <Modal
                trigger={<Button primary onClick={this.handleOpen}>New Claim</Button>}
                open={modalOpen}
                size={valid ? 'small' : 'mini'}
                onClose={this.handleClose}
            >
                <Modal.Header>New Claim</Modal.Header>
                <Modal.Content>
                    <Segment loading={processing} basic>
                        {
                            processError &&
                            <Message negative>
                                <Message.Header>{errorMsg}</Message.Header>
                                <p>{errorData}</p>
                            </Message>
                        }
                        <Form.Input
                            fluid
                            icon='gift'
                            iconPosition='left'
                            label='Please enter the Gift ID'
                            placeholder='Enter Gift ID'
                            id="giftID"
                            value={giftID}
                            onChange={this.handleChange}
                        />
                        {
                            // Checking if already claimed
                            (!claimed) ?
                                // Not claimed. Checking if the record is valid
                                valid &&
                                // Record is valid. Showing claim details
                                <Fragment>
                                    <Table singleLine>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Discount Voucher Details</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>Title</Table.Cell>
                                                <Table.Cell>{giftData.GiftName}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Claim Email</Table.Cell>
                                                <Table.Cell>{giftData.ShippingEmail}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Unit Discount Rs.</Table.Cell>
                                                <Table.Cell>{giftData.UnitPrice}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Qty</Table.Cell>
                                                <Table.Cell>{giftData.RedeemQTY}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Total Discount Rs.</Table.Cell>
                                                <Table.Cell>{parseInt(giftData.UnitPrice) * parseInt(giftData.RedeemQTY)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Your Rebate</Table.Cell>
                                                <Table.Cell>{(parseInt(giftData.UnitPrice) * parseInt(giftData.RedeemQTY)) * dppRebate}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    {
                                        // Checking if the code is sent
                                        (!codeSent) ?
                                            // Code not send. Show instructions to get the code
                                            <Message info>
                                                <Message.Header>This record is valid and you can issue it. Before You Proceed</Message.Header>
                                                <Message.List>
                                                    <Message.Item>Please check if the customer has accces to the {giftData.ShippingEmail} email.</Message.Item>
                                                    <Message.Item>If they don't have access to above email they they cannot claim this.</Message.Item>
                                                    <Message.Item>If they have access to above email you can click next and it will send a security code to {giftData.ShippingEmail}. Please ask them to provide the code and enter it here to complete the transaction</Message.Item>
                                                </Message.List>
                                            </Message>
                                            :
                                            // Code sent. Show instructions to complete the transaction
                                            <Fragment>
                                                <Message info>
                                                    <Message.Header>Code successfully sent.</Message.Header>
                                                    <Message.List>
                                                        <Message.Item>Code successfully sent to the registered email ({giftData.ShippingEmail})</Message.Item>
                                                        <Message.Item>Please get the security code from the customer and enter below to complete the transaction</Message.Item>
                                                        <Message.Item>Please note that this code is valid only for 5 minutes</Message.Item>
                                                    </Message.List>
                                                </Message>
                                                <Form.Input
                                                    fluid
                                                    icon='lock'
                                                    iconPosition='left'
                                                    label='Please enter the Security Code'
                                                    placeholder='Enter Security Code'
                                                    id="securityCode"
                                                    value={securityCode}
                                                    onChange={this.handleChange}
                                                />
                                            </Fragment>
                                    }

                                </Fragment>
                                :
                                <Message positive>
                                    <Message.Header>Claim success.</Message.Header>
                                    <p>Tranasction completed</p>
                                </Message>
                        }
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    {
                        claimed ?
                            <Button onClick={this.handleClose}>Close</Button>
                            :
                            <Fragment>
                                <Button onClick={this.handleClose}>Cancel</Button>
                                <Button onClick={this.handleNext} color='blue'>Next</Button>
                            </Fragment>
                    }

                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        newClaim: state.newClaim,
        dppRebate: state.config.config.dppRebate
    }
}

export default connect(mapStateToProps, {
    checkGiftDetails,
    sendClaimSecurityCode,
    confirmClaim,
    clearGiftCheckDetails
})(NewClaim);