import React, { Component } from 'react';
import HomePage from './HomePage';

class Home extends Component{
    render(){
        return(
            <HomePage/>
        )
    }
}

export default Home;