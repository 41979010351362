const initState = {
    loading: false,
    loaded: false,
    loadError: false,
    errorMsg: '',
    errorMsgData: '',
    claimHistoryRecords: [],
    sumOfClaimValue: 0,
    sumOfRebateValue: 0
}

const claimHistoryReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_CLAIM_HISTORY_STARTED':
            return {
                ...initState,
                loading: true
            }
        case 'LOAD_CLAIM_HISTORY_SUCCESS':
            return {
                ...initState,
                loaded: true,
                claimHistoryRecords: action.payload.claimHistoryRecords,
                sumOfClaimValue: action.payload.sumOfClaimValue,
                sumOfRebateValue: action.payload.sumOfRebateValue
            }
        case 'LOAD_CLAIM_HISTORY_ERROR':
            return {
                ...initState,
                loadError: true,
                errorMsg: action.payload.errorMsg,
                errorMsgData: action.payload.errorMsgData
            }
        case 'LOAD_CLAIM_HISTORY_CLEAR':
            return initState
        default:
            return state
    }
}

export default claimHistoryReducer;