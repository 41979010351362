export const checkGiftDetails = (giftID) => {
    return (dispatch, getState, { getFirestore }) => {
        try {
            dispatch({ type: 'CHECK_GIFT_DETAILS_STARTED' });

            //Checking if the giftID is valid
            const gifts = getFirestore().collection('gifts');

            gifts.where('ShipID', '==', giftID).get().then(query => {
                if (query.empty) {
                    //Invalid gift ID. Passing a error message.
                    dispatch({
                        type: 'CHECK_GIFT_DETAILS_ERROR', payload: {
                            errorMsg: 'Sorry. Entered code is not correct',
                            errorData: 'Please check and enter agian'
                        }
                    })
                } else {
                    //Gift ID is available. Checking if already claimed
                    const { claimed } = query.docs[0].data();
                    const giftDocID = query.docs[0].id

                    if (claimed) {
                        //Gift is already claimed.
                        dispatch({
                            type: 'CHECK_GIFT_DETAILS_ERROR', payload: {
                                errorMsg: 'Sorry this item is already claimed',
                                errorData: 'If you think this is a mistake please send an email to support@hik-portal.lk mentioning the Gift ID'
                            }
                        })
                    } else {
                        //Gift is not claimed.
                        //Getting the details to showing to claim.
                        const { GiftName, UnitPrice, RedeemQTY, ShippingEmail } = query.docs[0].data();

                        dispatch({
                            type: 'CHECK_GIFT_DETAILS_SUCCESS',
                            payload: {
                                GiftName,
                                UnitPrice,
                                RedeemQTY,
                                giftDocID,
                                ShippingEmail: ShippingEmail
                            }
                        })
                    }
                }
            }).catch(error => {
                dispatch({
                    type: 'CHECK_GIFT_DETAILS_ERROR', payload: {
                        errorMsg: 'Error in getting gift details. Please send a mail to support@hik-portal.lk with the error details',
                        errorData: error.toString()
                    }
                })
            })



        } catch (error) {
            dispatch({
                type: 'CHECK_GIFT_DETAILS_ERROR', payload: {
                    errorMsg: 'Unknown error in getting gift details. Please send a mail to support@hik-portal.lk with the error details',
                    errorData: error.toString()
                }
            })
        }
    }
}


export const sendClaimSecurityCode = (giftDocID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'SEND_SECURITY_CODE_STARTED' })

        const { customerID, id } = getState().auth.userData;

        //Updating gifts data to send the code.
        getFirestore().collection('gifts').doc(giftDocID).update({
            sendSecurityCode: true,
            lastRequestUserID: id,
            lastRequestCustomerID: customerID
        }).then(() => {
            dispatch({ type: 'SEND_SECURITY_CODE_SUCCESS' })
        }).catch((error) => {
            dispatch({
                type: 'SEND_SECURITY_CODE_ERROR', payload: {
                    errorMsg: 'Error in sending the security code.',
                    errorData: error.toString()
                }
            })
        })

    }
}

export const confirmClaim = (giftDocID, securityCode) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'CONFIRM_CLAIM_STARTED' })
        try {
            //Checking if the security code is valid
            //PENDING_DEV: Need to handle this part with the backend to avoid security issues
            //All Firestore calls should be switched to API calls with security before deployment

            //Getting gift data from the system
            getFirestore().collection('gifts').doc(giftDocID).get().then((giftData) => {
                const { lastSecurityCode, lastSecurityCodeTime } = giftData.data();

                if (lastSecurityCode && lastSecurityCode === securityCode) {
                    //Security code is valid.
                    //Checking if the 5 minutes have passed

                    const timeElapsed = (new Date() - lastSecurityCodeTime.toDate()) / 1000;

                    if (timeElapsed < 300) {
                        // Less than 5 minutes elapsed
                        // Completing the transaction
                        //Getting customerID and userID
                        const { customerID, id } = getState().auth.userData;
                        const { UnitPrice, RedeemQTY } = getState().newClaim.giftData;
                        const { dppRebate } = getState().config.config;
                        const currentDate = new Date();

                        //Updating gifts data
                        getFirestore().collection('gifts').doc(giftDocID).update({
                            claimed: true,
                            claimedCustomerID: customerID,
                            claimedUserID: id,
                            claimedTime: getFirebase().firestore.FieldValue.serverTimestamp(),
                            claimedYear: currentDate.getFullYear(),
                            claimedMonth: (currentDate.getMonth() +1),
                            totalClaimedValue: parseInt(UnitPrice)*parseInt(RedeemQTY),
                            totalRebateValue: parseInt(UnitPrice)*parseInt(RedeemQTY)*dppRebate
                        }).then(() => {
                            //Updating giftDocID under customer claims
                            getFirestore().collection('customers').doc(customerID).update({
                                claimedGifts: getFirebase().firestore.FieldValue.arrayUnion(giftDocID)
                            }).then(() => {
                                dispatch({ type: 'CONFIRM_CLAIM_SUCCESS' })
                            }).catch((error) => {
                                dispatch({
                                    type: 'CONFIRM_CLAIM_ERROR', payload: {
                                        errorMsg: 'Error in updating customer data. ',
                                        errorData: error.toString()
                                    }
                                })
                            })

                        }).catch((error) => {
                            dispatch({
                                type: 'CONFIRM_CLAIM_ERROR', payload: {
                                    errorMsg: 'Error in updating gift details. ',
                                    errorData: error.toString()
                                }
                            })
                        })
                    } else {
                        //Security code is not valid. Dispatching error
                        dispatch({
                            type: 'CONFIRM_CLAIM_ERROR', payload: {
                                errorMsg: 'Entered security code is expired ',
                                errorData: 'Please close this window and retry again'
                            }
                        })
                    }


                } else {
                    //Security code is not valid. Dispatching error
                    dispatch({
                        type: 'CONFIRM_CLAIM_ERROR', payload: {
                            errorMsg: 'Entered security code is invalid.',
                            errorData: 'Please check and enter again'
                        }
                    })
                }
            }).catch(error => {
                //Error in getting gift data
                dispatch({
                    type: 'CONFIRM_CLAIM_ERROR', payload: {
                        errorMsg: 'Error in getting gift data from the database. ',
                        errorData: error.toString()
                    }
                })
            })
        } catch (error) {
            dispatch({
                type: 'CONFIRM_CLAIM_ERROR', payload: {
                    errorMsg: 'Unknown error in confirming transaction. ',
                    errorData: error.toString()
                }
            })
        }

    }
}

export const clearGiftCheckDetails = () => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({ type: 'GIFT_DETAILS_CLEAR' })
    }
}