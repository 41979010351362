//Define all routes here.
//This can be used when programatic navigation is required via "history.push('path')"
export const allRoutes = {
    section: {
        create: '/section/create'
    }
}

//Define main navigation configuration here
export const mainNavConfig = [
    { name: 'home', link: '/', id: 'home' },
]


