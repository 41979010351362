import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

const renderTextInput = ({ input, meta, otherProps }) => {
    return (
        <Form.Input
            {...input}
            {...otherProps}
            error={meta.touched && meta.error ? { content: meta.error, pointing: 'below' } : false}
        />
    )
}


const renderSelectInput = ({ input, meta, otherProps }) => {

    return (
        <Form.Select
            {...otherProps}
            value={input.value}
            onChange={(e, { value }) => input.onChange(value)}
            error={meta.touched && meta.error ? { content: meta.error, pointing: 'below' } : false}
        />
    )
}

const renderRadioInput = ({ input, meta, otherProps }) => {
    return (
        <Form.Group inline>
            <label>{otherProps.mainLabel}</label>
            {
                otherProps.buttons && otherProps.buttons.map((radioButton) => {
                    return (
                        <Form.Radio
                            label={radioButton.label}
                            value={radioButton.value}
                            checked={input.value === radioButton.value}
                            key={radioButton.value}
                            onChange={(e, { value }) => input.onChange(value)}
                        />
                    )
                })
            }
        </Form.Group>
    )
}

const renderTextArea = ({ input, meta, otherProps }) => {
    return (
        <Form.TextArea
            {...input}
            {...otherProps}
            error={meta.touched && meta.error ? { content: meta.error, pointing: 'below' } : false}
        />
    )
}

const renderCheckbox = ({ input, meta, otherProps }) => {
    return (
        <Form.Checkbox
            onChange={() => input.onChange(input.value ? !input.value : true)}
            checked={input.value ? input.value : false}
            {...otherProps}
            error={meta.touched && meta.error ? { content: meta.error, pointing: 'below' } : false}
        />
    )
}


// START OF ADDITION DROP DOWN
var options = [];

const handleAddition = (value) => {
    options = [{ text: value, value }, ...options]
}

const renderAdditionDropdown = ({ input, meta, otherProps }) => {
    if (options.length === 0 && input.value.length > 0) {
        input.value.map(val => {
            return handleAddition(val);
        })
    }

    return (
        <Dropdown
            {...otherProps}
            value={input.value}
            onChange={(e, { value }) => input.onChange(value)}
            onAddItem={(e, { value }) => handleAddition(value)}
            search
            selection
            fluid
            multiple
            allowAdditions
            options={options}
            error={meta.touched && meta.error ? { content: meta.error, pointing: 'below' } : false}
        />
    )
}
//END OF ADDITION DROPDOWN

export {
    renderTextInput,
    renderSelectInput,
    renderRadioInput,
    renderTextArea,
    renderCheckbox,
    renderAdditionDropdown
}