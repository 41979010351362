export const submitEmail = (email) => {
    return async (dispatch, getState, { getFirestore }) => {
        try {
            //Show loading
            dispatch({ type: 'SIGNIN_PROCESSING' })

            //Getting user details
            const users = getFirestore().collection('users')
            const query = await users.where('email', '==', email).get();

            //Checking if the user details are available.
            if (query.empty) {
                //User details are not availabel. Creating a new user
                await users.add({
                    email,
                    sendLoginCode: true
                })

            } else {
                //User details are available. Updating the user record to send the login code.
                const docID = query.docs[0].id;
                await users.doc(docID).update({ sendLoginCode: true })
            }
            //Complete code trigger process
            dispatch({ type: 'SIGNIN_CODE_TRIGGERED' },)
        } catch (error) {
            //Error in code trigger process
            dispatch({
                type: 'SIGNIN_ERROR',
                payload: {
                    authErrorMsg: ' Error in sending the security code. Please contact the system administrator',
                    authErrorData: error
                }
            })
        }
    }
}

export const submitCode = (creds) => {
    return async (dispatch, getState, { getFirestore }) => {
        try {
            //Show loading
            dispatch({ type: 'SIGNIN_PROCESSING' });

            //Getting user data
            const { email, passcode } = creds;

            const users = getFirestore().collection('users')

            const query = await users.where('email', '==', email).get();

            //Check if the user data is available
            if (!query.empty) {
                
                const data = query.docs[0].data();
                const id = query.docs[0].id;

                //Checking if the entered codes are matching
                if (data.lastLoginCode && data.lastLoginCode === parseInt(passcode)) {
                    //Codes are matching. Check if the time elapsed is less than 5 minites.
                    const timeElapsed = (new Date() - data.lastLoginCodeTime.toDate()) / 1000;

                    if (timeElapsed < 300) {
                        // Less than 5 minutes elapsed
                        // Confirming the login
                        console.log('Login Success')
                        dispatch({ type: 'SIGNIN_SUCCESS', payload: {...data, id} });


                    } else {
                        //More than 5 minutes elapsed
                        //Entered codes are not matching
                        dispatch({
                            type: 'SIGNIN_ERROR',
                            payload: {
                                authErrorMsg: 'Security code is expired. Please try again.'
                            }
                        })
                    }
                } else {
                    //Entered codes are not matching
                    dispatch({
                        type: 'INVALID_CODE',
                        payload: {
                            authErrorMsg: 'Invalid code. Please check and enter again'
                        }
                    })
                }
            } else {
                //User data is not available. Dispatching the error.
                dispatch({
                    type: 'SIGNIN_ERROR',
                    payload: {
                        authErrorMsg: 'Error in getting the user data. Please contact the system administrator'
                    }
                })
            }
        
        } catch (error) {
            dispatch({ type: 'SIGNIN_ERROR', error })
        }
    }
}

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SIGNOUT_SUCCESS' })
    }
}