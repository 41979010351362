const initState = {
    submitting: false,
    submitted: false,
    submitError: false,
    errorMsg: '',
    errorData: ''
}

const registrationReducer = (state = initState, action) => {
    switch (action.type) {
        case 'DPP_REGISTRATION_STARTED':
            return {
                ...initState,
                submitting: true
            }
        case 'DPP_REGISTRATION_ERROR':
            const { errorMsg, errorData } = action.payload;
            return {
                ...initState,
                submitError: true,
                errorMsg,
                errorData
            }
        case 'DPP_REGISTRATION_SUCCESSFUL':
            return {
                ...initState,
                submitted: true
            }

        default:
            return state;
    }
}

export default registrationReducer;