export const registerDPP = (values) => {
    return (dispatch, getState, { getFirestore }) => {

        try {
            dispatch({ type: 'DPP_REGISTRATION_STARTED' });

            const { companyName, companyType, contactPerson, contactNumber, purchaseCompanyName, country, id } = values;

            //Checking if required data is available.
            if (companyName && companyType && contactPerson && contactNumber && purchaseCompanyName && country && id) {
                //All required data available
                //Update user data

                getFirestore().collection('users').doc(id).update({
                    companyName,
                    companyType: 'dpp',
                    country,
                    contactPerson,
                    contactNumber,
                    purchaseCompanyName,
                    registered: true,
                    pendingApproval: true,
                    registrationSource: 'epartner'
                })
                    .then(() => {
                        dispatch({ type: 'DPP_REGISTRATION_SUCCESSFUL' })
                    })
                    .catch(error => {
                        dispatch({
                            type: 'DPP_REGISTRATION_ERROR', payload: {
                                errorMsg: 'Error in registration. Please send a mail to support@hik-portal.lk with the error details',
                                errorData: error.toString()
                            }
                        })
                    })
            } else {
                //All required data not available. Dispatching error
                dispatch({
                    type: 'DPP_REGISTRATION_ERROR', payload: {
                        errorMsg: 'All required fields are not entered',
                        errorData: 'Please check the form and submit again'
                    }
                })
            }




        } catch (error) {
            dispatch({
                type: 'DPP_REGISTRATION_ERROR', payload: {
                    errorMsg: 'Error in registration. Please send a mail to support@hik-portal.lk with the error details',
                    errorData: error.toString()
                }
            })
        }


    }
}