//Core imports
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container, Grid, Message } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

//Main navigation releated imports
import MainNav from './components/common/MainNav';

import { mainNavConfig } from './config/navConfig';

//Layout imports
import Home from './components/layouts/home';

//Import action creators
import { loadRemoteConfig } from './store/actions/configActions';

class App extends Component {

  componentDidMount() {
    this.props.loadRemoteConfig();
  }

  render() {

    //Protectig the component to prevent access without login
    const { loggedIn } = this.props.auth;
    const { config } = this.props;

    if (!loggedIn) return <Redirect to='/signin' />

    if (config && config.loadError) {
      return (
        <Container fluid>
          <MainNav />
          <Grid centered padded columns={2}>
            <Grid.Column>
              <Message negative>
                <Message.Header>{config.errorMsg}</Message.Header>
                <p>{config.errorMsgData}</p>
              </Message>
            </Grid.Column>
          </Grid>
        </Container>
      )
    }

    return (
      <Container fluid>
        <MainNav mainNavConfig={mainNavConfig} />
        {/* Define all main app components here */}
        <Container fluid style={{ padding: '10px 30px' }}>
          <Switch>
            <Route path='/' component={Home} exact />
          </Switch>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Global state logged from App componenet', state);
  return {
    auth: state.auth,
    config: state.config
  }
}

export default compose(
  connect(mapStateToProps, {
    loadRemoteConfig
  }),
  firestoreConnect(props => [
    //Loading user role to manage access. 
    //This will be stored in global state as firestore.data.accessRole
    //Any component can get the access rules by mapping state to props from above location
    { collection: 'roles', doc: props.role, storeAs: 'accessRole' }
  ])
)(App);
