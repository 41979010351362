import React, { Component } from 'react';
import { Modal, Button, Segment, Form, Header, Grid, Message, Statistic } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { loadClaimHistory,clearClaimHistory } from '../../../store/actions/checkHistoryActions';
import DataTable from '../../common/DataTable';

const initState = {
    modalOpen: false,
    year: '',
    month: ''
}

const columns = [
    { title: 'ID', data: 'ShipID' },
    { title: 'Claimed Date', data: 'claimedTime' },
    { title: 'Company Name', data: 'CompanyName' },
    { title: 'Person Name', data: 'ShippingName' },
    { title: 'Mobile Number', data: 'ShippingPhone' },
    { title: 'Email', data: 'ShippingEmail' },
    { title: 'Gift Name', data: 'GiftName' },
    { title: 'Qty', data: 'RedeemQTY' },
    { title: 'Unit Discount (Rs.)', data: 'UnitPrice' },
    { title: 'Total Discount (Rs.)', data: 'totalClaimedValue' },
    { title: 'Rebate Value (Rs.)', data: 'totalRebateValue' },
]

class CheckHistory extends Component {
    state = initState;

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => {
        this.props.clearClaimHistory();
        this.setState(initState);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleNext = () => {
        const { year, month } = this.state;

        //Check if the year and month is selected
        if (!isNaN(year) && !isNaN(month)) {
            this.props.loadClaimHistory(year, month)
        }
    }

    render() {
        const { modalOpen, year, month } = this.state;

        const { loading, loaded, loadError, errorMsg, errorMsgData, claimHistoryRecords, sumOfClaimValue, sumOfRebateValue } = this.props.claimHistory;

        return (
            <Modal
                trigger={<Button primary onClick={this.handleOpen}>Claim History</Button>}
                open={modalOpen}
                size={loaded ? 'large' : 'tiny'}
                onClose={this.handleClose}
            >
                <Modal.Header>Claim History</Modal.Header>
                <Modal.Content scrolling>
                    <Segment basic loading={loading}>
                        <Header as='h4'>Please enter report year and month </Header>
                        {
                            loadError &&
                            <Message negative>
                                <Message.Header>{errorMsg}</Message.Header>
                                <p>{errorMsgData}</p>
                            </Message>
                        }
                        <Grid columns={loaded ? 2 : 1} verticalAlign='top'>
                            <Grid.Column>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input
                                                fluid
                                                icon='calendar alternate'
                                                iconPosition='left'
                                                label='Please enter year'
                                                placeholder='Example: 2021'
                                                id="year"
                                                value={year}
                                                onChange={this.handleChange}
                                                type='number'
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Field>
                                                <Form.Input
                                                    fluid
                                                    icon='calendar alternate'
                                                    iconPosition='left'
                                                    label='Please enter month'
                                                    placeholder='Example: 2'
                                                    id="month"
                                                    value={month}
                                                    onChange={this.handleChange}
                                                    type='number'
                                                />
                                            </Form.Field>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Grid.Column>
                            {
                                loaded &&
                                <Grid.Column textAlign='right'>
                                    <Statistic size='tiny'>
                                        <Statistic.Label>Total Discounts Issued</Statistic.Label>
                                        <Statistic.Value>{sumOfClaimValue.toLocaleString()}</Statistic.Value>
                                    </Statistic>
                                    <Statistic size='tiny'>
                                        <Statistic.Label>Total Rebate Value</Statistic.Label>
                                        <Statistic.Value>{sumOfRebateValue.toLocaleString()}</Statistic.Value>
                                    </Statistic>
                                </Grid.Column>
                            }
                        </Grid>

                        {
                            (Array.isArray(claimHistoryRecords) && claimHistoryRecords.length > 0) &&
                            <DataTable data={claimHistoryRecords} columns={columns} />
                        }

                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    <Button onClick={this.handleNext} color='blue'>Preview</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        claimHistory: state.claimHistory
    }
}

export default connect(mapStateToProps, {
    loadClaimHistory,
    clearClaimHistory
})(CheckHistory);