import React, { Component } from 'react';
import { Menu, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

import Brand from '../../resources/logo.png';

class MainNav extends Component {
  state = {
    activeItem: 'home'
  }

  handleItemClick = (e, { id }) => {
    this.setState({ activeItem: id });
  }

  handleSignOut = () => {
    this.props.signOut();
  }


  render() {

    const { activeItem } = this.state;
    const { mainNavConfig } = this.props;
    return (
      <div>
        <Menu style={{ padding: '0px 30px' }}>
          <Menu.Item header>
            <Image src={Brand ? Brand : 'Brand'} size='small' />
          </Menu.Item>

          <Menu.Item header>
            <p>Hik-ePartner<br />Web Portal</p>
          </Menu.Item>

          {
            // Takes in main navigation configuration passed as a property and generate menu items
            mainNavConfig ?
            mainNavConfig.map((navItem) => {
                return (
                  <Menu.Item
                    onClick={this.handleItemClick}
                    name={navItem.name}
                    key={navItem.id}
                    active={activeItem === navItem.id}
                    as={Link}
                    to={navItem.link}
                  />
                )
              })
              :
              null
          }


          <Menu.Menu position='right'>
            <Menu.Item
              name='logout'
              active={activeItem === 'logout'}
              onClick={this.handleSignOut}
            />
          </Menu.Menu>
        </Menu>

      </div>
    )
  }
}



const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

const mapStateToProps = (state) => {
  return {
    accessRole: state.firestore.data.accessRole
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNav);