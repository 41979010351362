import React, { Component } from 'react';
import {
    Header,
    Image,
    Segment,
    Grid,
    Icon,
    Button,
    Message,
    Form,
    List,
    Dimmer,
    Loader
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { renderTextInput, renderSelectInput, renderCheckbox } from '../../common/FormFields';
import { Field, reduxForm } from 'redux-form';

import { registerDPP } from '../../../store/actions/registrationActions';
import Operation from '../operation';

import ePartnerIcon from '../../../resources/icon.png'


class HomePage extends Component {
    onSubmit = (formValues) => {
        this.props.registerDPP({ ...formValues, id: this.props.auth.userData.id });
    }

    render() {

        const { auth, config } = this.props;
        const { email, registered, approved, companyName, companyType, contactPerson, contactNumber, country } = auth.userData;
        const { loading } = config;
        const { countries, companyTypes } = config.config;
        const { submitting, submitError, errorMsg, errorData, submitted } = this.props.registration;

        var companyTypeText = '';
        var countryText = '';

        //Getting companyTypeText
        if(companyType && companyTypes){
            if(_.find(companyTypes, { key: companyType })){
                companyTypeText = _.find(companyTypes, { key: companyType }).text;
            }
        }

        //Getting countryText
        if(country && countries){
            if(_.find(countries, { key: country })){
                countryText = _.find(countries, { key: country }).text;
            }
        }


        const companyDetails = [
            {
                key: 'Registered Email',
                value: email,
                icon: 'mail'
            },
            {
                key: 'Company Name',
                value: companyName,
                icon: 'building outline'
            },
            {
                key: 'Company Type',
                value: companyTypeText,
                icon: 'warehouse'
            },

            {
                key: 'Contact Person',
                value: contactPerson,
                icon: 'user'
            },
            {
                key: 'Contact Number',
                value: contactNumber,
                icon: 'phone'
            },
            {
                key: 'Country',
                value: countryText,
                icon: 'phone'
            }
        ]

        if (loading) {
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            )
        }

        return (
            <Segment basic>

                <Grid stackable textAlign='center' centered verticalAlign='bottom'>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Image circular size='medium' src={ePartnerIcon} centered />
                            <Header as='h2' style={{ marginTop: '-40px' }}>
                                Welcome to the Hik-ePartner Web Portal
                                <Header.Subheader>
                                    This portal is for Hikvison authorized resellers only.
                                </Header.Subheader>
                                <Header.Subheader>
                                    Authorized resellers can use this portal to issue gift vouchers to Hik-ePartner gift winners. This platform should not be misused.
                                </Header.Subheader>
                            </Header>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                {
                    //Check if the user is already registered
                    registered ?
                        //User is registered
                        //Checking if the user account is approved.
                        approved ?
                            //Show home screen
                            <Operation />
                            :
                            //User account is not approved.
                            //Show approval pending screen
                            <Grid centered columns={3} padded>
                                <Message info style={{ marginTop: '40px' }}>
                                    <Message.Header>Your registraion is pending for the approval.</Message.Header>
                                    <p>Usually registrations are approved within 1 week. If your approval is not recieved within a week please send a mail to support@hik-portal.lk</p>
                                </Message>
                            </Grid>
                        :
                        //User is not registered. Show registration form.
                        <Segment textAlign='center' basic loading={submitting}>

                            <Grid centered columns={2}>

                                {
                                    //Checking if the submittion is completed
                                    submitted ?
                                        //Form is submitted. Show success message
                                        <Grid.Column>
                                            <Message info>
                                                <Message.Header>Your data successfully submitted.</Message.Header>
                                                <p>Please wait till we review and approve your account. Usually this process will be completed within 7 days. You can log out now and login later to check the status </p>
                                            </Message>
                                        </Grid.Column>
                                        :
                                        //Form is not submitted. Show form
                                        <Grid.Column>
                                            <Message info>
                                                <Message.Header>You must register before you can start using this portal.</Message.Header>
                                                <p>Please submit below details. </p>
                                                <p>If you are not a Hikvision authorized reseller pleae DO NOT register </p>
                                            </Message>
                                            {
                                                submitError &&
                                                <Message negative>
                                                    <Message.Header>{errorMsg}</Message.Header>
                                                    <p>{errorData}</p>
                                                </Message>
                                            }

                                            <Form autoComplete='off'>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <Field
                                                            name='companyName'
                                                            component={renderTextInput}
                                                            otherProps={{
                                                                label: 'Company Name',
                                                                placeholder: 'Company Name'
                                                            }}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Field
                                                            name='companyType'
                                                            component={renderSelectInput}
                                                            otherProps={{
                                                                fluid: true,
                                                                label: 'Your Company Type',
                                                                placeholder: 'Your Company Type',
                                                                options: companyTypes
                                                            }}
                                                        />
                                                    </Form.Field>


                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <Field
                                                            name='contactPerson'
                                                            component={renderTextInput}
                                                            otherProps={{
                                                                label: 'Contact Name',
                                                                placeholder: 'Contact Name'
                                                            }}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Field
                                                            name='contactNumber'
                                                            component={renderTextInput}
                                                            otherProps={{
                                                                label: 'Contact Number',
                                                                placeholder: 'Ex: 0712345678'
                                                            }}
                                                        />
                                                    </Form.Field>

                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <Field
                                                            name='purchaseCompanyName'
                                                            component={renderTextInput}
                                                            otherProps={{
                                                                label: 'Name of the Company that You Purchase Items',
                                                                placeholder: 'Name of the Company that You Purchase Items'
                                                            }}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Field
                                                            name='country'
                                                            component={renderSelectInput}
                                                            otherProps={{
                                                                fluid: true,
                                                                label: 'Your Country',
                                                                placeholder: 'Your Country',
                                                                options: countries
                                                            }}
                                                        />
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <Field
                                                            name='agreement'
                                                            component={renderCheckbox}
                                                            otherProps={{
                                                                label: `I confirm that above details are true and correct. And I have no issue to use ${email} as my primary email to access hik-portal.lk and it's services`
                                                            }}
                                                        />
                                                    </Form.Field>

                                                </Form.Group>
                                                <Button onClick={this.props.handleSubmit(this.onSubmit)}>Submit</Button>
                                            </Form>
                                        </Grid.Column>

                                }

                            </Grid>
                        </Segment>
                }

                {/* Footer for registered users to see theri registration details */}
                {registered &&
                    <Grid stackable textAlign='center' centered verticalAlign='bottom'>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Message info>
                                    <Header as='h2'>Registration Details</Header>


                                    <List horizontal>
                                        {
                                            companyDetails.map(companyDetail => {
                                                return (
                                                    <List.Item as='a' key={companyDetail.key}>
                                                        <Icon name={companyDetail.icon} />
                                                        <List.Content>
                                                            <List.Header>{companyDetail.key}</List.Header>
                                                            <List.Description>
                                                                {companyDetail.value}
                                                            </List.Description>
                                                        </List.Content>
                                                    </List.Item>
                                                )
                                            })
                                        }
                                    </List>
                                </Message>
                                <Header as='h3' >
                                    If there are any changes to above details
                                    <Header.Subheader>
                                        Please send us an email to support@hik-portal.lk notifying the changes
                                    </Header.Subheader>
                                    <Header.Subheader>
                                        © 2021 - Hik-Portal.LK
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </Segment>
        )
    }
}

const validate = (values) => {
    const errors = {}

    const {
        companyName,
        companyType,
        contactPerson,
        contactNumber,
        purchaseCompanyName,
        country,
        agreement
    } = values;

    if (!companyName) { errors.companyName = "Required" };
    if (!companyType) { errors.companyType = "Required" };
    if (!contactPerson) { errors.contactPerson = "Required" };
    if (!contactNumber) {
        errors.contactNumber = "Required"
    } else {
        if (!(/^\d{10}$/.test(contactNumber))) {
            errors.contactNumber = "Please enter valid phone number"
        }
    }
    if (!purchaseCompanyName) { errors.purchaseCompanyName = "Required" };
    if (!country) { errors.country = "Required" };
    if (!agreement) { errors.agreement = "Required" };

    return errors;
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        registration: state.registration,
        config: state.config,
    }
}

export default reduxForm({
    form: 'registerDPP',
    validate
})(connect(mapStateToProps, {
    registerDPP
})((HomePage)));
