const initState = {
    processing: false,
    valid: false,
    codeSent: false,
    claimed: false,
    processError: false,
    errorMsg: '',
    errorData: '',
    giftData: null
}

const newClaimReducer = (state = initState, action) => {
    switch (action.type) {
        // Checking gift validity
        case 'CHECK_GIFT_DETAILS_STARTED':
            return {
                ...initState,
                processing: true
            }
        case 'CHECK_GIFT_DETAILS_ERROR':
            return {
                ...initState,
                processError: true,
                errorMsg: action.payload.errorMsg,
                errorData: action.payload.errorData
            }
        case 'CHECK_GIFT_DETAILS_SUCCESS':
            return {
                ...initState,
                valid: true,
                giftData: action.payload
            }
        //Sending security code when gift is valid
        case 'SEND_SECURITY_CODE_STARTED':
            return {
                ...initState,
                valid: state.valid,
                giftData: state.giftData,
                processing: true
            }
        case 'SEND_SECURITY_CODE_ERROR':
            return {
                ...initState,
                valid: state.valid,
                giftData: state.giftData,
                processError: true,
                errorMsg: action.payload.errorMsg,
                errorData: action.payload.errorData
            }
        case 'SEND_SECURITY_CODE_SUCCESS':
            return {
                ...initState,
                valid: state.valid,
                giftData: state.giftData,
                codeSent: true
            }
        //Process claim
        case 'CONFIRM_CLAIM_STARTED':
            return {
                ...initState,
                valid: state.valid,
                giftData: state.giftData,
                codeSent: true,
                processing: true
            }
        case 'CONFIRM_CLAIM_ERROR':
            return {
                ...initState,
                valid: state.valid,
                giftData: state.giftData,
                codeSent: true,
                processError: true,
                errorMsg: action.payload.errorMsg,
                errorData: action.payload.errorData
            }
        case 'CONFIRM_CLAIM_SUCCESS':
            return {
                ...initState,
                valid: state.valid,
                giftData: state.giftData,
                codeSent: true,
                claimed: true
            }
        //Reset state
        case 'GIFT_DETAILS_CLEAR':
            return initState
        default:
            return state;
    }
}

export default newClaimReducer;