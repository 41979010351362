import authReduder from './authReducer';
import configReducer from './configReducer';
import registrationReducer from './registrationReducer'
import newClaimReducer from './newClaimReducer';
import claimHistoryReducer from './claimHistoryReducer';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'
import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

const rootReducer = combineReducers({
    auth: authReduder,
    config: configReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    form: reduxFormReducer,
    registration: registrationReducer,
    newClaim: newClaimReducer,
    claimHistory: claimHistoryReducer
})

export default rootReducer;