const initState = {
    loggedIn: false,
    codeTriggered: false,
    authError: false,
    authErrorMsg: '',
    authErrorData: '',
    loading: false,
    userData: null
}


//STATE FOR DEV - Instance for approved account
// const initState = {
//     loggedIn: true,
//     codeTriggered: false,
//     authError: false,
//     authErrorMsg: '',
//     authErrorData: '',
//     loading: false,
//     userData: {
//         approved: true,
//         companyName: "Hikvision Sri Lanka",
//         contactNumber: "0770400664",
//         contactPerson: "Yasantha Hennayake",
//         customerID: "TbMgruHny7UbAdex34Iy",
//         email: "yasantha@hikvision.com",
//         id: "kegCokcpHMMiIF19xZSL",
//         lastLoginCode: 881754,
//         lastLoginCodeTime: 'Timestamp {seconds: 1621974173, nanoseconds: 78000000}',
//         nd: "itg",
//         pendingApproval: false,
//         registered: true,
//         registrationSource: "epartner",
//         sendLoginCode: false,
//         type: "dpp",
//     },
// }

//STATE FOR DEV - Instance where user is logged in and pending registration
// const initState = {
//     loggedIn: true,
//     codeTriggered: false,
//     authError: false,
//     authErrorMsg: "",
//     authErrorData: "",
//     loading: false,
//     userData: {
//         email: "mail2yasantha@gmail.com",
//         lastLoginCode: 328662,
//         sendLoginCode: false,
//         id: "5i24uMXhlmQyzXrWYUkP"
//     }
// }


const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SIGNIN_PROCESSING':
            return {
                ...state,
                loading: true
            }
        case 'SIGNIN_CODE_TRIGGERED':
            return {
                ...state,
                codeTriggered: true,
                authError: false,
                authErrorMsg: '',
                authErrorData: '',
                loading: false
            }
        case 'SIGNIN_ERROR':
            return {
                ...initState,
                authError: true,
                authErrorMsg: action.payload.authErrorMsg,
                authErrorData: action.payload.authErrorData,
                loading: false,
            }
        case 'INVALID_CODE':
            return {
                ...state,
                authError: true,
                authErrorMsg: action.payload.authErrorMsg,
                loading: false,
            }
        case 'SIGNIN_SUCCESS':
            return {
                ...initState,
                loggedIn: true,
                userData: action.payload
            }
        case 'SIGNOUT_SUCCESS':
            return initState;
        default:
            return state;
    }
}

export default authReducer;