import moment from 'moment';

export const loadClaimHistory = (year, month) => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({ type: 'LOAD_CLAIM_HISTORY_STARTED' });
        try {
            const { customerID } = getState().auth.userData;

            getFirestore().collection('gifts')
                .where('claimedCustomerID', '==', customerID)
                .where('claimedYear', '==', parseInt(year))
                .where('claimedMonth', '==', parseInt(month))
                .where('claimed', '==', true)
                .get().then((querySnapshot) => {

                    if(querySnapshot.empty){
                        dispatch({
                            type: 'LOAD_CLAIM_HISTORY_ERROR', payload: {
                                errorMsg: 'No data available. Please check the entered year and month again',
                                errorData: 'If you think this is an error please send a mail to support@hik-portal.lk with the details'
                            }
                        })
                    } else {
                        const claimHistoryRecords = [];

                        var sumOfClaimValue = 0;
                        var sumOfRebateValue = 0;

                        querySnapshot.forEach(doc=>{
                            const {
                                ShipID,
                                claimedTime,
                                CompanyName,
                                ShippingName,
                                ShippingPhone,
                                ShippingEmail,
                                GiftName,
                                RedeemQTY,
                                UnitPrice,
                                totalClaimedValue,
                                totalRebateValue
                            } = doc.data();

                            const claimRecord = {
                                ShipID,
                                claimedTime: moment(claimedTime.toDate()).format('MMMM Do YYYY, h:mm:ss a'),
                                CompanyName,
                                ShippingName,
                                ShippingPhone,
                                ShippingEmail,
                                GiftName,
                                RedeemQTY,
                                UnitPrice,
                                totalClaimedValue,
                                totalRebateValue
                            }

                            sumOfClaimValue = sumOfClaimValue + parseInt(totalClaimedValue);
                            sumOfRebateValue = sumOfRebateValue + parseInt(totalRebateValue);

                            claimHistoryRecords.push(claimRecord);
                        })
    
                        dispatch({type: 'LOAD_CLAIM_HISTORY_SUCCESS', payload: {
                            claimHistoryRecords,
                            sumOfClaimValue,
                            sumOfRebateValue
                        }})
                    }
                }).catch((error) => {
                    dispatch({
                        type: 'LOAD_CLAIM_HISTORY_ERROR', payload: {
                            errorMsg: 'Error in getting claim history. Please send a mail to support@hik-portal.lk with the error details',
                            errorData: error.toString()
                        }
                    })
                })
        } catch (error) {
            dispatch({
                type: 'LOAD_CLAIM_HISTORY_ERROR', payload: {
                    errorMsg: 'Unknown error in getting claim history. Please send a mail to support@hik-portal.lk with the error details',
                    errorData: error.toString()
                }
            })
        }

    }
}

export const clearClaimHistory = () => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({type: 'LOAD_CLAIM_HISTORY_CLEAR'})
    }
}