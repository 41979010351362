import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import Brand from '../../resources/logo.png';
import { connect } from 'react-redux';
import { submitEmail, submitCode } from '../../store/actions/authActions';
import { validateEmail } from '../common/CommonFunctions';

class SignIn extends Component {
  state = {
    email: '',
    passcode: '',
    validationError: '',
    validationErrorMsg: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleEmailSubmit = (e) => {
    e.preventDefault();

    const { email } = this.state;

    //Checking if the entered email address is valid
    if (validateEmail(email)) {
      this.setState({ validationError: false, validationErrorMsg: '' })
      this.props.submitEmail(email.toLowerCase())
    } else {
      this.setState({ validationError: true, validationErrorMsg: 'Please enter valid email address' })
    }
  }


  handleCodeSubmit = (e) => {
    e.preventDefault();
    const { email, passcode } = this.state;
    this.props.submitCode({ email: email.toLowerCase(), passcode });
  }

  render() {
    const { loggedIn, codeTriggered, authError, authErrorMsg, authErrorData, loading } = this.props.auth;

    const { email, passcode, validationError, validationErrorMsg } = this.state;

    if (loggedIn) return <Redirect to='/' />

    return (
      <div className='login-form'>
        {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
        <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image src={Brand} size='small' centered />
            <Header as='h3'>
              Hik-ePartner Portal
              <Header.Subheader>
                Sri Lanka
              </Header.Subheader>
            </Header>
            {
              authError ?
                <Message negative>
                  <Message.Header>Login failed</Message.Header>
                  <p>{authErrorMsg ? authErrorMsg : ''}</p>
                  <p>{authErrorData ? authErrorData.toString() : ''}</p>
                </Message>
                :
                null
            }
            {
              validationError ?
                <Message negative>
                  <Message.Header>Login failed</Message.Header>
                  <p>{validationErrorMsg}</p>
                </Message>
                :
                null
            }
            <Segment stacked>

              <Form size='large' onSubmit={this.handleEmailSubmit}>

                {
                  codeTriggered ?
                    <p>Please enter code that you recieved to the entered email</p>
                    :
                    <p>Please enter your email address to login to the portal</p>
                }

                <Form.Input
                  fluid icon='user'
                  iconPosition='left'
                  placeholder='E-mail address'
                  id="email"
                  value={email}
                  onChange={this.handleChange}
                  required
                  disabled={codeTriggered || loading}
                />
                {
                  !codeTriggered &&
                  <Button color='blue' fluid size='large' loading={loading} disabled={loading}>
                    Submit
                    </Button>
                }


              </Form>
              <br />
              {
                codeTriggered &&
                <Form size='large' onSubmit={this.handleCodeSubmit}>

                  <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='Code'
                    id="passcode"
                    value={passcode}
                    onChange={this.handleChange}
                    required
                  />
                  <Button color='blue' fluid size='large' loading={loading} disabled={loading}>
                    Submit
                    </Button>
                </Form>
              }
            </Segment>

            {/* <Message>
              New to us? <Link to='/signup'>Sign up</Link>
            </Message> */}
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}


export default connect(mapStateToProps, {
  submitEmail,
  submitCode
})(SignIn)