import _ from 'lodash';

export const loadRemoteConfig = () => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({ type: 'LOAD_REMOTE_CONFIG_STARTED' })
        try {
            getFirestore().collection('config').doc('config').get()
                .then((doc) => {

                    const countries = JSON.parse(doc.data().countries);
                    const companyTypes = JSON.parse(doc.data().companyTypesForEndUser);
                    const dppRebate = doc.data().dppRebate;

                    const dppType = [_.find(companyTypes, { key: 'dpp'})];

                    const payload = {
                        countries,
                        companyTypes: dppType,
                        dppRebate
                    }

                    dispatch({ type: 'LOAD_REMOTE_CONFIG_COMPLETED', payload });

                }).catch(error => {
                    dispatch({
                        type: 'LOAD_REMOTE_CONFIG_ERROR',
                        payload: {
                            errorMsg: 'Error in getting configuration data. Please contact the system administrator via support@hik-portal.lk',
                            errorMsgData: error.toString()
                        }
                    })
                })

        } catch (error) {
            dispatch({
                type: 'LOAD_REMOTE_CONFIG_ERROR',
                payload: {
                    errorMsg: 'Error in getting configuration data. Please contact the system administrator via support@hik-portal.lk',
                    errorMsgData: error.toString()
                }
            })
        }

    }
}