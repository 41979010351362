import React, { Component } from 'react';
import {
    Header,
    Segment,
    Grid,
    Icon,
    Divider
} from 'semantic-ui-react';

import NewClaim from './NewClaim';
import CheckHistory from './CheckHistory';

class Operation extends Component {
    render() {
        return (
            <Grid centered>
                <Grid.Column>
                    <Segment placeholder>
                        <Grid columns={4} stackable textAlign='center'>
                            <Divider vertical>Or</Divider>
                            <Grid.Column>
                                <Header icon><Icon name='gift' />Claims</Header>
                                <NewClaim />
                            </Grid.Column>
                            <Grid.Column>
                                <Header icon><Icon name='columns' />History</Header>
                                <CheckHistory />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}

export default Operation;